<template>
    <el-dialog v-model="dialogVisible" :lock-scroll="true" title="" width="600px" top="10vh" :show-close="false">
        <div class="content" ref="print">
            <div class="print center" ref="canvas">
                <div class="title">
                    <img src="@/assets/imgs/tongzhi.png" class="leve-title" alt="通知">
                </div>
                <p class="text">为规范本公司人事管理制度，方便就业人员办理信息登记，自即日起，凡是通过本公司安排的就业人员均需扫描以下二维码，完善个人信息后提交保存。</p>
                <div class="qr-code">
                    <div id="qrCode" ref="qrCodeDiv"></div>
                </div>
                <div class="flex_end">
                    <div>
                        <p>{{ detail.company_name }}</p>
                        <p class="mt_10">{{ detail.date }}</p>
                    </div>
                </div>
            </div>
            <div class="utils flex_col no-print">
                <el-button type="primary" size="medium" @click="handlePrint">打印</el-button>
                <el-button type="primary" size="medium" @click="download">保存二维码</el-button>
                <el-button type="primary" size="medium" @click="dialogVisible=false">取消</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import QRCode from 'qrcodejs2'
import print from 'td-print'
import html2canvas from 'html2canvas'
export default {
	data() {
		return {
			dialogVisible: false,
			detail: {}
		}
	},
	methods: {
		getQRcode(type) {
			this.$axios({
				url: `/ent/v3/usercenter/company/invite/qrcode/${type}`,
				method: "GET",
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.detail = res.data
					document.getElementById("qrCode").innerHTML = ""
					this.$nextTick(() => {
						new QRCode(this.$refs.qrCodeDiv, {
							text: res.data.qrcode_url + '&type=' + type,
							width: 200,
							height: 200,
							colorDark: "#000000", //二维码颜色
							colorLight: "#ffffff", //二维码背景色
							correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
						})
					})
				}
			})
		},
		handlePrint() {
			print(this.$refs.print, {
				style: `<style>.print{margin-top:300px;}</style>`
			})
		},
		download() {
			let a = document.createElement('a')
			a.style.display = 'none'

			let canvas_el = this.$refs.canvas
			var width = canvas_el.clientWidth
			var height = canvas_el.clientHeight
			var canvas = document.createElement("canvas")
			let scale = 4
			canvas.width = width * scale
			canvas.height = height * scale
			canvas.style.width = width + "px"
			canvas.style.height = height + "px"
			var context = canvas.getContext("2d")
			//然后将画布缩放，将图像放大两倍画到画布上
			context.scale(scale, scale)
			html2canvas(this.$refs.canvas, {
				backgroundColor: null,
				scale: 1,
				canvas: canvas
			}).then((canvasData) => {
				let dataURL = canvasData.toDataURL("image/png")
				a.setAttribute('href', dataURL)
				a.setAttribute('download', '信息登记二维码.png')
				a.click()
				this.$message.success('图片已保存至本地')
			})
		}
	}
}
</script>

<style lang='less' scoped>
.content {
    margin-top: -46px;
    padding: 20px;
    background: #d4d4d4;
    position: relative;
    /deep/.el-dialog__body {
        padding: 0;
    }

    .print {
        height: 780px;
        background: #fff;
        padding: 70px 45px;
        .title {
            width: 76px;
            overflow: hidden;
            margin: 0 auto 30px;
            .leve-title {
                width: 74px;
                height: 38px;
            }
        }
        .text {
            font-size: 16px;
            color: #333;
            line-height: 24px;
            text-indent: 32px;
            text-align: left;
        }
        .line {
            border-top: 1px dashed #ededed;
            margin: 20px -45px 60px;
            position: relative;
            &::after,
            &::before {
                content: "";
                position: absolute;
                width: 15px;
                height: 15px;
                border-radius: 100%;
                background: #d4d4d4;
                top: -7px;
                z-index: 2;
            }
            &::after {
                left: -7px;
            }
            &::before {
                right: -7px;
            }
        }
        .qr-code {
            padding: 3px;
            width: 220px;
            height: 220px;
            // background: #f2f2f2;
            margin: 47px auto;
        }
    }
    .utils {
        position: absolute;
        right: -125px;
        top: 0;
        z-index: 99;
        .el-button {
            width: 110px;
            height: 42px;
            margin: 0 0 10px;
            font-size: 14px;
            background: #fff;
            color: #333;
            border-color: #fff;
            &:hover {
                background: #1890ff;
                color: #fff;
                border-color: #1890ff;
            }
        }
    }
}
</style>