<template>
    <el-dialog v-model="dialogVisible" :lock-scroll="true" :before-close="close" width="800px">
        <div class="main">
            <el-table :data="tableData" border style="width: 100%;margin-top:30px" :row-style="rowStyle">
                <el-table-column prop="mobile" label="提现单号" align="center">
                </el-table-column>
                <el-table-column prop="final_amount" label="提现金额" align="center">
                </el-table-column>
                <el-table-column prop="create_time" label="提现时间" align="center">
                </el-table-column>
                <el-table-column label="提现状态" align="center">
                    <template #default="{ row }">
                        <div class="flex_cen" >
                            <i class="round danger"></i>
                            <p class="tag">提现中{{ row.number }}</p>
                        </div>
                    </template>
                </el-table-column>

                <template #empty>
                    <div>
                        <p class="loading_" v-if="!finish">
                            <i class="el-icon-loading fs_20"></i> 努力加载中...
                        </p>
                        <p class="loading_" v-else>暂无数据</p>
                    </div>
                </template>
            </el-table>
            <div class="flex_cen mt_20">
                <el-pagination class="page" background :page-size="limit" :current-page="page" layout="prev, pager, next" :total="total" @current-change="load" v-if="tableData.length!=0">
                </el-pagination>
            </div>
        </div>
    </el-dialog>
</template>
<script>
export default {
	name: 'WithdrawalRecord',
	data() {
		return {
			dialogVisible: false,
			page: 1,
			limit: 10,
			finish: false,
			tableData: [],
			total_credits: 0,
			total: 0
		}
	},
	created() {
		this.getInfo()
	},
	methods: {
		load(page) {
			this.page = page
			this.getInfo()
		},
		getInfo() {
			this.$axios({
				url: '/ent/v3/usercenter/company/members',
				method: 'GET',
				data: {
					page: this.page,
					limit: this.limit
				},
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.tableData = res.data.list
					this.total_credits = res.data.total_credits
					this.total = res.data.total
					this.finish = this.tableData.length === 0
				} else {
					this.finish = true
				}
			})
		},
		handleWithdrawal() {
			this.$confirm("所有金额将一次性提现，确定申请提现?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {

			})
		},
		close() {
			this.dialogVisible = false
			this.$emit('WRclose')
		}
	}
}
</script>

<style lang='less' scoped>
.main {
    padding: 20px 20px 30px;
    background-color: #fff;
    min-height: 30vh;

    /deep/.el-table thead tr th {
        background: #ededed !important;
        /deep/.cell {
            font-size: 14px;
            font-weight: bold;
            color: #333;
        }
    }
    .el-table__header {
        background: #ededed !important;
    }
}
.round {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    margin-right: 5px;
    background: #ccc;
    &.success {
        background: #67C23A;
    }
    &.warning {
        background: #E6A23C;
    }
    &.danger {
        background: #F56C6C;
    }
}
</style>