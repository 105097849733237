<template>
    <el-dialog v-model="dialogVisible" :lock-scroll="true" :before-close="close" width="800px">
        <div class="main">
            <div class="flex">
                <div class="flex_c">
                    <p class="mr_20">累计获得：<span class="color bold">{{ accumulate_money }}元</span></p>
                    <p>当前可提现：<span class="price_color bold">{{ accumulate_money }}元</span></p>
                </div>
                <!-- <div class="flex_c">
                    <el-button type="primary" :disabled="!tableData.length" class="fs_14" @click="handleWithdrawal">申请提现</el-button>
                    <el-button class="fs_14" :disabled="!tableData.length" @click="handleRecord">提现记录</el-button>
                </div> -->
            </div>
            <el-table :data="tableData" border style="width: 100%;margin-top:20px" height="50vh" :row-style="rowStyle">
                <el-table-column label="用户头像 / 姓名" align="center">
                    <template #default="{row}">
                        <div class="flex_cen">
                            <el-avatar :src="row.avatar" :size="38" class="avatar mr_10" alt="头像">
                                <img :src="
                                row.gender == 1
                                    ? $store.state.nanAvatar
                                    : $store.state.nvAvatar
                            " />
                            </el-avatar>
                            <span>{{ row.realname }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="mobile" label="手机号" align="center">
                </el-table-column>
                <el-table-column prop="amount" label="奖励金额" align="center">
                </el-table-column>
                <el-table-column prop="create_time" label="奖励时间" align="center">
                </el-table-column>

                <template #empty>
                    <div>
                        <p class="loading_" v-if="!finish">
                            <i class="el-icon-loading fs_20"></i> 努力加载中...
                        </p>
                        <p class="loading_" v-else>暂无数据</p>
                    </div>
                </template>
            </el-table>
            <div class="flex_cen mt_20">
                <el-pagination class="page" background :page-size="limit" :current-page="page" layout="prev, pager, next" :total="total" @current-change="load" v-if="tableData.length!=0">
                </el-pagination>
            </div>
        </div>
    </el-dialog>
</template>
<script>
export default {
	name: 'PromotionReward',
	data() {
		return {
			dialogVisible: false,
			page: 1,
			limit: 10,
			finish: false,
			tableData: [],
			accumulate_money: '',
			total: 0
		}
	},
	methods: {
		load(page) {
			this.page = page
			this.fetchData()
		},
		fetchData() {
			this.$axios({
				url: '/ent/v3/partner/reward',
				method: 'GET',
				data: {
					page: this.page,
					limit: this.limit
				},
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.tableData = res.data.list
					this.accumulate_money = res.data.accumulate_money
					this.total = res.data.total
					this.finish = this.tableData.length === 0
				} else {
					this.finish = true
				}
			})
		},
		handleWithdrawal() {
			this.$confirm("所有金额将一次性提现，确定申请提现?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				this.$message.success('已提交申请，请耐心等待审核')
			})
		},
		handleRecord() {
			this.$emit('showWR')
		},
		close() {
			this.dialogVisible = false
		}
	}
}
</script>

<style lang='less' scoped>
.main {
    padding: 20px 20px 30px;
    background-color: #fff;
    min-height: 30vh;

    /deep/.el-table thead tr th {
        background: #ededed !important;
        /deep/.cell {
            font-size: 14px;
            font-weight: bold;
            color: #333;
        }
    }
    .el-table__header {
        background: #ededed !important;
    }
}
</style>