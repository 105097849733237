<template>
    <div class='contain' v-infinite-scroll="load" :infinite-scroll-disabled="finish" :infinite-scroll-distance="50">
        <searchBar :searchInput="false"></searchBar>
        <div class="bgf main">
            <div class="details">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="闲赋居民合伙人" name="area" v-if="$store.state.companyInfo.is_partner"></el-tab-pane>
                    <el-tab-pane label="入职登记" name="unemployed"></el-tab-pane>
                    <el-tab-pane label="离职职员" name="leve"></el-tab-pane>
                    <el-tab-pane label="毕业学生" name="student"></el-tab-pane>
                    <!-- <el-tab-pane label="闲赋居民" name="residents"></el-tab-pane> -->
                </el-tabs>
                <div class="utils-btn flex_c" v-if="activeName !== 'leve'">
                    <template v-if="activeName === 'area'">
                        <div class="item btn fs_14 flex_cen mr_20 color_999" @click="handleShowPromotion">
                            <i class="el-icon-tickets el-icon fs_17 mr_5"></i>推广奖励明细
                        </div>
                        <div class="item btn fs_14 flex_cen mr_20 color_999" @click="handleAddPartner">
                            <i class="el-icon-circle-plus-outline el-icon fs_18 mr_5"></i>添加县区合伙人
                        </div>
                    </template>
                    <div class="item btn fs_14 flex_cen mr_20 color_999" @click="handleShowQrcode" v-else>
                        <i class="icon qr-icon"></i>{{ qrName }}专属二维码
                    </div>
                </div>
            </div>
            <template v-if="activeName === 'area'">
                <div class="flex">
                    <div class="flex fs_14">
                        {{ areaText }}：
                        县区<span class="color mt_3 fs_18">3</span>人 &nbsp;|&nbsp;
                        职站<span class="color mt_3 fs_18">3</span>人 &nbsp;|&nbsp;
                        推广用户<span class="color mt_3 fs_18">3</span>人 &nbsp;|&nbsp;
                        本企业累计收益<span class="price_color mt_3 fs_18">3.00</span>元
                    </div>

                    <!-- <el-select v-model="areaValue" placeholder="请选择" @change="handleChange">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select> -->
                </div>
                <div class="area-item bgf mb_10 mt_10" v-for="(item, index) in list" :key="index">
                    <div class="tip-icon" v-if="!item.status">已停用</div>
                    <div class="flex">
                        <div class="flex_c">
                            <el-avatar shape="square" :size="50" :src="item.avatar" class="mr_20">
                                <img :src="item.gender == '男' ? $store.state.nanAvatar : $store.state.nvAvatar" />
                            </el-avatar>
                            <div class="flex_1">
                                <div class="flex_c">
                                    <p class="fs_20 bold color_333 mr_20">{{ item.realname }}</p>
                                    <p class="fs_16 color_333 mr_20">{{ item.mobile }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="flex_end flex_1">
                            <div class="fs_14 color_333 info center address">
                                <p>负责区域</p>
                                <p class="color_666 mt_10">{{ item.region_city }}-{{ item.region_name }}</p>
                            </div>
                            <div class="fs_14 color_333 info center">
                                <p>职站合伙人</p>
                                <p class="color mt_10 fs_18">{{ item.site_user_num }}</p>
                            </div>
                            <div class="fs_14 color_333 info center">
                                <p>已推广用户</p>
                                <p class="color mt_10 fs_18">{{ item.reward_num }}</p>
                            </div>
                            <div class="info del center">
                                <el-button plain @click="deactivate(item.id, item.realname, item.status)">{{
                                    !item.status ? '启用' : '停用' }}</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <el-row class="list" :gutter="20" v-if="list.length != 0">
                    <el-col class="item" v-for="(item, index) in list" :key="index" :span="12" @click="showDetail(item.id)">
                        <el-card :body-style="{ padding: '0px' }" shadow="hover">
                            <div class="top flex_c bottom_link">
                                <el-avatar shape="circle" :size="50" :fit="'contain'" :src="item.avatar" class="mr_20">
                                    <img :src="$store.state.baseAvatar" />
                                </el-avatar>
                                <div class="flex_col flex_1">
                                    <div class="flex">
                                        <div class="bold">
                                            <span class="fs_20 color_333 mr_20">{{ item.realname }}</span>
                                            <span class="fs_18 color_000">{{ item.mobile }}</span>
                                        </div>
                                    </div>
                                    <p class="flex_c fs_14 color_333 use_tip mt_10">
                                        <span class="line" v-if="item.current_city">{{ item.current_city }}</span>
                                        <span class="line" v-if="item.gender">{{ item.gender }}</span>
                                        <span class="line" v-if="item.age">{{ item.age }}</span>
                                        <span class="line" v-if="item.work_exp">{{ item.work_exp }}</span>
                                        <span class="line" v-if="item.education">{{ item.education }}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="bottom">
                                <p class="flex_c fs_14">
                                    <span class="label info_color">求职关注点：</span>
                                    <span class="line one color_666">{{ item.labels || '-' }}</span>
                                </p>
                                <template v-if="activeName != 'student'">
                                    <div class="flex_c fs_14 mt_10">
                                        <span class="label info_color">上一份工作：</span>
                                        <p class="flex_c use_tip color_666 flex_1" v-if="item.last_work_exp">
                                            <span class="line one" style="max-width:210px">{{
                                                item.last_work_exp.company_name }}</span>
                                            <span class="line">{{ item.last_work_exp.job_name }}</span>
                                            <span class="line flex_1">{{ item.last_work_exp.start_time }}至{{
                                                item.last_work_exp.end_time }}</span>
                                        </p>
                                        <p v-else class="flex_c use_tip color_666 flex_1"> - </p>
                                    </div>
                                </template>
                                <template v-if="activeName == 'student'">
                                    <div class="flex_c fs_14 mt_10">
                                        <span class="label info_color mr_15">教育经历：</span>
                                        <p class="flex_c use_tip color_666 flex_1" v-if="item.edu_list.length != 0">
                                            <span class="line flex_1 one">{{ item.edu_list[0].school_name }}</span>
                                            <span class="line">{{ item.edu_list[0].major }}</span>
                                            <span class="line">{{ item.edu_list[0].start_time }}至{{
                                                item.edu_list[0].end_time }}</span>
                                        </p>
                                        <p v-else class="flex_c use_tip color_666 flex_1"> - </p>
                                    </div>
                                </template>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </template>

            <empty :finish="finish" :length="list.length"></empty>
        </div>

        <!-- 专属二维码 -->
        <qr-code-dialog ref="qrCodeDialog"></qr-code-dialog>

        <promotion-reward ref="promotionReward" @showWR="showWR"></promotion-reward>
        <withdrawal-record ref="withdrawalRecord" @WRclose="WRclose"></withdrawal-record>
        <add-partner-code ref="addPartnerCode"></add-partner-code>

        <resumeInfo ref="resumeInfo" :showInvited="false"></resumeInfo>

        <el-backtop :bottom="100"></el-backtop>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import qrCodeDialog from './components/qrcode-dialog'
import PromotionReward from './components/promotionReward'
import WithdrawalRecord from './components/withdrawalRecord.vue'
import AddPartnerCode from './components/addPartnerCode.vue'
import resumeInfo from '../talents-hall/dialog/resume-info'
export default {
	name: 'Strategic',
	components: { qrCodeDialog, resumeInfo, PromotionReward, WithdrawalRecord, AddPartnerCode },
	data() {
		return {
			activeName: 'unemployed',
			list: [],
			page: 1,
			limit: 16,
			finish: false,
			areaValue: 'all',
			areaText: '所有县区',
			options: [ {
				value: 'all',
				label: '所有县区'
			}, {
				value: '选项1',
				label: '黄金糕'
			}, {
				value: '选项2',
				label: '双皮奶'
			}, {
				value: '选项3',
				label: '蚵仔煎'
			}, {
				value: '选项4',
				label: '龙须面'
			}, {
				value: '选项5',
				label: '北京烤鸭'
			} ]
		}
	},
	created() {
		if (this.$store.state.companyInfo.is_partner) {
			this.activeName = 'area'
		}
		this.init()
	},
	computed: {
		...mapGetters({
			companyInfo: "getCompanyInfo"
		}),
		qrName() {
			if (this.activeName === 'unemployed') {
				return '入职登记'
			} else if (this.activeName === 'leve') {
				return '离职职员'
			} else if (this.activeName === 'student') {
				return '毕业学生'
			} else if (this.activeName === 'residents') {
				return '闲赋居民'
			}
			return ''
		}
	},
	watch: {
		'$store.state.companyInfo': {
			handler(n) {
				if (n.is_partner) {
					this.activeName = 'area'
					this.init()
				}
			},
			deep: true
		}
	},
	methods: {
		load() {
			this.page++
			this.init()
		},
		init() {
			if (this.activeName === 'leve') {
				this.getList_leve()
			} else if (this.activeName === 'area') {
				this.getList_area()
			} else {
				this.getList()
			}
		},
		handleChange(e) {
			this.areaText = this.options.filter(item => item.value === e)[0].label
		},
		getList() {
			this.$axios({
				url: "/ent/v3/usercenter/company/invite/list",
				method: "GET",
				hideLoading: true,
				data: {
					page: this.page,
					limit: this.limit,
					type: this.activeName == 'leve' ? '' : this.activeName
				}
			}).then(res => {
				if (res.code === 200) {
					this.list = this.page == 1 ? res.data.list : this.list.concat(res.data.list)
					this.list.forEach(item => {
						if (Array.isArray(item.labels)) {
							item.labels = item.labels.join('、')
						}
					})
					this.finish = this.list.length >= res.data.total
				} else {
					this.finish = true
				}
			})
		},
		getList_leve() {
			this.$axios({
				url: "/ent/v3/usercenter/company/invite/dimissions",
				method: "GET",
				hideLoading: true,
				data: {
					page: this.page,
					limit: this.limit
					// type: this.activeName=='student'?this.activeName:''
				}
			}).then(res => {
				if (res.code === 200) {
					this.list = this.page == 1 ? res.data.list : this.list.concat(res.data.list)
					this.list.forEach(item => {
						if (Array.isArray(item.labels)) {
							item.labels = item.labels.join('、')
						}
					})
					this.finish = this.list.length >= res.data.total
				} else {
					this.finish = true
				}
			})
		},
		getList_area() {
			this.$axios({
				url: "/ent/v3/partner",
				method: "GET",
				hideLoading: true,
				data: {
					page: this.page,
					limit: this.limit
				}
			}).then(res => {
				if (res.code === 200) {
					this.list = this.page == 1 ? res.data.list : this.list.concat(res.data.list)
					this.finish = this.list.length >= res.data.total
				} else {
					this.finish = true
				}
			})
		},

		handleClick() {
			this.page = 1
			this.list = []
			this.finish = false
			this.init()

		},
		handleShowQrcode() {
			let type = this.activeName
			this.$refs.qrCodeDialog.getQRcode(type)
			this.$refs.qrCodeDialog.dialogVisible = true
		},
		deactivate(id, realname, status) {
			let ststusText = status ? '停用' : '启用'
			this.$confirm(`确定${ststusText}合伙人 <span class="color_000 bold">${realname}</span>?`, "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				dangerouslyUseHTMLString: true,
				type: "warning"
			}).then(() => {
				this.$axios({
					url: `/ent/v3/partner/${id}`,
					method: "post",
					hideLoading: true,
					data: {
						status: Number(!status)
					}
				}).then(res => {
					if (res.code === 200) {
						this.handleClick()
						this.$message.success(`合伙人 ${realname} 已${ststusText}`)
					}
				})
			})
		},
		showDetail(id) {
			if (this.activeName === 'unemployed') {
				this.$axios({
					url: `/ent/v3/usercenter/company/invite/detail/${id}`,
					method: "GET",
					hideLoading: true
				}).then(res => {
					if (res.code === 200) {
						this.$refs.resumeInfo.id = id
						this.$refs.resumeInfo.data = res.data
						this.$refs.resumeInfo.dialogVisible = true
					}
				})
			}
		},
		handleAddPartner() {
			this.$refs.addPartnerCode.dialogVisible = true
			this.$refs.addPartnerCode.getQRcode(this.activeName)
		},
		handleShowPromotion() {
			this.$refs.promotionReward.dialogVisible = true
			this.$refs.promotionReward.fetchData()
		},
		showWR() {
			this.$refs.promotionReward.dialogVisible = false
			this.$refs.withdrawalRecord.dialogVisible = true
		},
		WRclose() {
			this.$refs.promotionReward.dialogVisible = true
		}
	}
}
</script>

<style lang='less' scoped>
/deep/.el-dialog__body {
    padding: 0;
}

.contain {
    min-height: 58vh;
}

.main {
    padding: 10px 50px 30px;
    min-height: 650px;

    .details {
        margin-top: 30px;
        position: relative;

        /deep/.el-tabs {
            .el-tabs__header {
                margin-bottom: 30px;
            }

            .el-tabs__active-bar {
                background-color: #1890ff;
            }

            .el-tabs__item {
                font-size: 16px;

                &.is-active {
                    color: #1890ff;
                }
            }
        }

        .utils-btn {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 9;

            .item {
                transition: color 0.2s ease;

                &:hover {
                    color: #1890ff !important;
                    transition: color 0.2s ease;

                    .auth-icon {
                        background: url(../../assets/imgs/icon/auth-icon1.png);
                        transition: all 0.2s ease;
                    }

                    .qr-icon {
                        background: url(../../assets/imgs/icon/qr-icon1.png);
                        transition: all 0.2s ease;
                    }

                    .el-icon {
                        color: #1890ff;
                    }
                }
            }

            .icon {
                width: 24px;
                height: 24px;
                background-size: 100% 100% !important;

                &.auth-icon {
                    background: url(../../assets/imgs/icon/auth-icon.png);
                    transition: all 0.2s ease;
                }

                &.qr-icon {
                    background: url(../../assets/imgs/icon/qr-icon.png);
                    transition: all 0.2s ease;
                }
            }
        }
    }
}

.list {
    .item {
        margin-bottom: 20px;

        .top,
        .bottom {
            padding: 20px 0 20px 30px;
        }
    }
}

.area-item {
    height: 110px;
    border-radius: 2px;
    border: 1px solid #ededed;
    padding: 30px 0 30px 30px;
    overflow: hidden;
    position: relative;

    .tip-icon {
        position: absolute;
        width: 130px;
        text-align: center;
        font-size: 12px;
        line-height: 24px;
        background: #f56c6c;
        color: #fff;
        transform: rotate(45deg) scale(.8);
        right: -46px;
        top: 6px;
        z-index: 2;
    }

    .avatar {
        width: 50px;
        height: 50px;
        margin-right: 20px;
    }

    .info {
        width: 150px;

        &.address {
            width: auto;
            padding: 0 40px;
        }
    }

    .info:not(:last-child) {
        border-right: 1px solid #ededed;
    }

    .el-button {
        width: 90px;
    }
}
</style>