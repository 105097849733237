<template>
    <el-dialog v-model="dialogVisible" :lock-scroll="true" title="" width="600px" top="10vh" :show-close="false">
        <div class="content" ref="print">
            <div class="print center" ref="canvas">

                <div class="qr-code">
                    <div id="qrCode" ref="qrCodeDiv"></div>
                    <img :src="companyInfo.logo" class="logo" alt="">
                </div>
                <p class="fs_16 flex_cen">「{{ companyInfo.company_name }}」邀请您加入县区合伙人</p>
                <p class="bold fs_16 flex_cen mt_20">请使用微信扫一扫功能，扫描上方二维码前往小程序完善您的信息</p>

            </div>
            <div class="utils flex_col no-print">
                <el-button type="primary" size="medium" @click="dialogVisible=false">取消</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import QRCode from 'qrcodejs2'
import print from 'td-print'
import html2canvas from 'html2canvas'
import { mapGetters } from 'vuex'
export default {
	data() {
		return {
			dialogVisible: false,
			detail: {}
		}
	},
	computed: {
		...mapGetters({
			companyInfo: 'getCompanyInfo'
		})
	},
	methods: {
		getQRcode(type) {

			if(document.getElementById("qrCode")) {
				document.getElementById("qrCode").innerHTML = ""
			}
			this.$nextTick(() => {
				new QRCode(this.$refs.qrCodeDiv, {
					text: `https://hr.zhiluinfo.com/partnerBind?id=${this.companyInfo.company_id}&type=${type}`,
					width: 200,
					height: 200,
					colorDark: "#000000", //二维码颜色
					colorLight: "#ffffff", //二维码背景色
					correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
				})
			})
		},
		handlePrint() {
			print(this.$refs.print, {
				style: `<style>.print{margin-top:300px;}</style>`
			})
		},
		download() {
			let a = document.createElement('a')
			a.style.display = 'none'

			let canvas_el = this.$refs.canvas
			var width = canvas_el.clientWidth
			var height = canvas_el.clientHeight
			var canvas = document.createElement("canvas")
			let scale = 4
			canvas.width = width * scale
			canvas.height = height * scale
			canvas.style.width = width + "px"
			canvas.style.height = height + "px"
			var context = canvas.getContext("2d")
			//然后将画布缩放，将图像放大两倍画到画布上
			context.scale(scale, scale)
			html2canvas(this.$refs.canvas, {
				backgroundColor: null,
				scale: 1,
				canvas: canvas
			}).then((canvasData) => {
				let dataURL = canvasData.toDataURL("image/png")
				a.setAttribute('href', dataURL)
				a.setAttribute('download', '信息登记二维码.png')
				a.click()
				this.$message.success('图片已保存至本地')
			})
		}
	}
}
</script>

<style lang='less' scoped>
.content {
    margin-top: -46px;
    padding: 20px;
    background: #d4d4d4;
    position: relative;
    /deep/.el-dialog__body {
        padding: 0;
    }

    .print {
        height: 500px;
        background: #fff;
        padding: 50px 45px;

        .qr-code {
            padding: 3px;
            width: 220px;
            height: 220px;
            // background: #f2f2f2;
            margin: 47px auto 15px;
            position: relative;
            .logo {
                position: absolute;
                width: 40px;
                height: 40px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                border-radius: 6px;
                overflow: hidden;
                background: #fff;
            }
        }
    }
    .utils {
        position: absolute;
        right: -125px;
        top: 0;
        z-index: 99;
        .el-button {
            width: 110px;
            height: 42px;
            margin: 0 0 10px;
            font-size: 14px;
            background: #fff;
            color: #333;
            border-color: #fff;
            &:hover {
                background: #1890ff;
                color: #fff;
                border-color: #1890ff;
            }
        }
    }
}
</style>